import React from "react";
import PrivateRoute from "../withPrivateRoute";
import { LoadingOutlined } from "@ant-design/icons/lib/icons";
import { Loader } from "@project/shared";
import styled from "styled-components";

const InitalLoader = styled.div`
  margin-top: 50vh;
  text-align: center;
`;

const Homepage = () => {
  const antIcon = <LoadingOutlined />;

  return (
    <InitalLoader>
      <Loader indicator={antIcon} size="large" />
    </InitalLoader>
  );
};
export default PrivateRoute(Homepage);
